section.App h3 {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  padding-bottom: 10px;
}
.project-text1 h6 {
  font-size: 33px;
  color: #fff;
  margin-bottom: 0px;
  padding-top: 106px;
}
section.prt-text {
  padding-top: 10px;
  padding-bottom: 0px;
}
section.ptr1-text {
  padding-bottom: 15px;
}
.project-text2 h6 {
  font-size: 33px;
  color: #fff;
  margin-bottom: 0px;
  padding-top: 106px;
}
.poto-text h3 {
  font-size: 38px;
  font-weight: 700;
  color: #610a0a;
}
.proto-txet {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  height: 439px;
  border-radius: 5px;
  margin-top: 30px;
}
.proto-txet img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.ourproject-text {
  padding: 18px;
}
.ourproject-text h4 {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0px;
}
.proto-txet:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.projectplan-text h5 {
  font-size: 20px;
  color: #000;
  font-weight: 700;
}
.projectplan-text p {
  font-size: 16px;
  margin-bottom: 5px;
}
.gas-text img {
  width: 100%;
}
.pro-text img {
  width: 100%;
}
.projectplan-text h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #7f1919;
}
.pro-text img {
  width: 100%;
}
.details-item img {
  width: 100%;
}
.pro-text h1 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #931414;
}
.pro-text {
  padding-top: 30px;
  padding-bottom: 30px;
}
marquee.sliderpast-text img {
  width: 100px;
  margin: 15px 40px;
  animation: marquee-8-1 25s linear infinite;
}
section.past-pro-text {
  padding-bottom: 0px;
  background-color: #9e9e9e14;
}
section.past-pro-text h3 {
  text-align: center;
  margin-bottom: 6px;
  font-weight: 700;
  padding: 15px;
  font-size: 20px;
  color: #000;
  border-bottom: 1px solid #cec7c7;
}
.pro-text h2 {
  font-size: 22px;
  font-weight: 700;
  padding-top: 0px;
  margin-bottom: 14px;
}
.ourproject-text p {
  font-size: 15px;
  color: #9d8d8d;
  padding: 10px 0px;
}
.pro-text P {
  font-size: 15px;
  font-weight: 500;
}
.details-jhulaghar {
  padding: 25px;
  margin: 20px 0;
  border-top: 4px solid #f2ba13;
  box-shadow: 0 0 20px 0 #dddddd8c;
  transition: 0.5s all ease;
  background-color: #fff;
  padding: 25px;
}
.details-item h2 {
  font-size: 28px;
  font-weight: 700;
  color: #000000;
  padding-bottom: 10px;
  border-bottom: 1px solid #f1efef;
  margin-bottom: 20px;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .project-text2 h6 {
    font-size: 30px;
    color: #fff;
    margin-bottom: 6px;
    padding-top: 101px;
  }
}
@media (max-width: 575px) {
  .details-item h2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .project-text1 h6 {
    font-size: 29px;
    margin-bottom: 0px;
    padding-top: 71px;
  }
  .project-text2 h6 {
    font-size: 25px;
    color: #fff;
    margin-bottom: 6px;
    padding-top: 76px;
  }
}
@media (max-width: 446px) {
  section.past-pro-text h3 {
    font-size: 15px;
  }
}

@media (max-width: 425px) {
  .project-text2 h6 {
    font-size: 21px;
  }
}
@media (max-width: 320px) {
  .project-text1 h6 {
    font-size: 22px;
    margin-bottom: 0px;
    padding-top: 50px;
  }
  .project-text2 h6 {
    font-size: 17px;
    margin-bottom: 0px;
    padding-top: 38px;
  }
  section.past-pro-text h3 {
    font-size: 13px;
  }
  .details-jhulaghar {
    margin: 10px 0;
}
}
