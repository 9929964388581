.term-text h4 {
    font-size: 24px;
}
.term-text p {
    font-size: 18px;
}
img.faq-img {
    width: 100%;
}
.accordion__button {
    font-size: 19px;
    font-weight: 500;
}
.accordion__panel p{
    font-size: 17px !important;
}
.testimonial-area.two .testimonial-content {
    margin-bottom: 0;
}
.testimonial-area .testimonial-content {
    padding: 50px 50px 50px 30px;
    height: 298px;
    border-radius: 30px 30px 30px 0;
    -webkit-box-shadow: 0 0 20px 0 #dddddd8c;
    box-shadow: 0 0 20px 0 #dddddd8c;
    position: relative;
    background-color: #fff;
    margin-top: 80px;
    margin-bottom: 30px;
}
.testimonial-area .testimonial-content:before {
    position: absolute;
    content: '';
    width: 80px;
    height: 100%;
    top: 0;
    left: -50px;
    background-color: #fff;
    border-radius: 30px 0 0 0;
}
content:before {
    position: absolute;
    content: '';
    width: 80px;
    height: 100%;
    top: 0;
    left: -50px;
    background-color: #fff;
    border-radius: 30px 0 0 0;
}
.testimonial-area .testimonial-content ul {
    /* text-align: left; */
    list-style-type: none;
    margin-bottom: 10px;
    display: flex;
    margin-left: -30px;
}
i.icofont-star.checked {
    color: #f7ad07;
    font-size: 25px;
    /* margin-left: -11px; */
}
.testimonial-area .testimonial-content p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 20px;
}
.testimonial-area .testimonial-content .quote {
    display: inline-block;
    color: #eeeefa;
    font-size: 55px;
    position: absolute;
    right: 30px;
    bottom: 20px;
}
.testimonial-area {
    background-color: #f9f9f9;
}
.testimonial-img h3 {
    font-size: 27px;
    font-weight: 600;
    color: #302c51;
    font-family: quicksand, sans-serif;
    margin-bottom: 0px;
    padding: 10px 0px 0px 0px;
}
.testimonial-img img {
    width: 100%;
}
.testimonial-img h6{
    font-size: 18px;
}
@media(max-width:991px){
    .testimonial-area .testimonial-content {
        margin-top: 10px;
       
    }
    .accordion {
        margin-top: 10px;
    }
    .testimonial-area .testimonial-content:before {
        width: 26px;
        left: 0px;
    }
    .testimonial-area.two .testimonial-content {
        margin-bottom: 15px;
    }
}
@media(max-width:425px){
    .testimonial-img h3 {
        font-size: 21px;
    }
    .testimonial-area .testimonial-content {
        padding: 13px 20px 50px 30px;
        height: auto;
    }
    .testimonial-area .testimonial-content p {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 16px;
    }
    .testimonial-area .testimonial-content .quote {
        display: inline-block;
        color: #eeeefa;
        font-size: 36px;
        position: absolute;
        right: 21px;
        bottom: 10px;
    }
}
@media(max-width:375px){
    .term-text h4 {
        font-size: 19px;
    }
    .term-text p {
        font-size: 15px;
    }
}
@media(max-width:320px){
    .accordion__button {
        font-size: 16px;
        font-weight: 500;
    }
}