.details-jhulaghar-1 h2 {
  font-size: 25px;
  color: #000;
  margin-bottom: 7px;
}
.details-jhulaghar-2 h2 {
  font-size: 25px;
  color: #000;
  margin-bottom: 7px;
}
.details-jhulaghar-1 p {
  margin-bottom: 7px;
}
.teamme-text h4 {
  font-size: 22px;
  text-align: center;
  color: #f2ba13;
  margin-bottom: 0px;
  font-family: "Jost", sans-serif;
  font-weight: 700;
}
.teamme-text h2 {
  font-size: 38px;
  text-align: center;
  font-weight: 700;
  color: #000;
}
.ty1 {
  position: relative;
  left: -11px;
}
.ty {
  position: relative;
  left: 12px;
}
.ty1 img {
  width: 100%;
}
.ty img {
  width: 100%;
}
.com-title {
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 0px;
}
.details-img {
  border-top: 4px solid #f2ba13;
  box-shadow: 0 0 20px 0 #dddddd8c;
  transition: 0.5s all ease;
  background-color: #fff;
  padding: 25px;
}
.home-list-pop {
  position: relative;
  overflow: hidden;
  background: #fff;
  padding: 15px 0px;
  margin-bottom: 30px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 7%);
  border: 1px solid #f1f2f3;
}
.col-md-10.home-list-pop-desc.inn-list-pop-desc p.sc-gKclnd.mcxen {
  font-size: 16px;
}

.list-pg-inn-sp {
  padding: 20px;
}
.col-md-10.home-list-pop-desc.inn-list-pop-desc h2.sc-gsDKAQ.djZNKz {
  margin-bottom: 6px;
}
.home-list-pop img {
  width: 100%;

  -webkit-object-fit: cover;
  -moz-object-fit: cover;
  -o-object-fit: cover;
  object-fit: cover;
}
.team-item {
  text-align: center;
  margin-bottom: 30px;
  border-radius: 30px 30px 0 30px;
  -webkit-box-shadow: 0 0 20px 0 #dddddd8c;
  box-shadow: 0 0 20px 0 #dddddd8c;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.team-item .top {
  position: relative;
}
.team-item .top img {
  width: 100%;
  border-radius: 10px;
  transition: 0.5s all ease;
  min-height: 217px;
}

.team-item .bottom {
  padding: 28px 25px 30px;
}
.team-item .bottom h3 {
  font-size: 20px;
  margin-bottom: 5px;
}
.list-pg-inn-sp h2.sc-gsDKAQ.djZNKz {
  color: #001234;
  font-size: 39px;
  line-height: 1.1;
  margin-bottom: 0px;
  font-weight: 600;

  letter-spacing: -0.3px;
}
.list-pg-inn-sp p.sc-gKclnd.mcxen {
  font-size: 15px;
}
.team-item .bottom span {
  display: block;
  color: #ff6015;
  font-size: 17px;
}
.post-inner img {
  width: 100%;
}
.details-img {
  padding: 25px;
}
.details-item h2 {
  font-size: 28px;

  font-weight: 700;
  color: #000000;
  padding-bottom: 10px;
  border-bottom: 1px solid #f1efef;
  margin-bottom: 12px;
}
.vision img {
  width: 100%;
}
.details-item h5 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}
.details-item p {
  font-size: 14px !important;
  color: #000;
}
.details-img ul {
  color: #000;
}
.title-bg-one {
  position: relative !important;
  top: -10px;
  background-image: url(../../assests/image/banner/titlebg1.jpg) !important;
}
.skills {
  position: relative !important;
  top: -10px;
  background-image: url(../../assests/image/banner/young-children-sitting-floor-playing.png) !important;
}
.livehood {
  background-image: url(../../assests/image/banner/livelihood-banner.jpg) !important;
}
.healthcare {
  background-image: url(../../assests/image/banner/nurse-measuring-patient-blood-pressure.png) !important;
}
.teams {
  background-image: url(../../assests/image/banner/group-people-working-out-business-plan-office.png) !important;
}
.tt {
  padding-top: 106px;
}
.bu-text h4 {
  font-size: 28px;
}
.page-title-area {
  height: 300px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
img.praga {
  width: 100%;
  padding: 20px;
}
.page-title-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #302c51;
  opacity: 0.75;
}
.page-title-area .title-item {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-top: 10px;
}
.page-title-area .title-item h2 {
  font-size: 50px;
  color: #fff;
  margin-bottom: 5px;
  font-family: "Jost", sans-serif;
}
.page-title-area .title-item ul li {
  list-style-type: none;
  display: inline-block;
  margin-left: 14px;
  font-size: 17px;
  text-decoration: none;
  margin-right: 18px;
  position: relative;
}
.page-title-area .title-item ul li:before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  top: 10px;
  left: 60px;
  background-color: #fff;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  -webkit-animation: rotate360 1s infinite linear;
  animation: rotate360 1s infinite linear;
  border: 2px solid #ff6015;
}
.page-title-area .title-item ul li:last-child:before {
  display: none;
}
.page-title-area .title-item ul li a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
}
.page-title-area .title-item ul li a:hover {
  color: #ff6015;
}
.page-title-area .title-item ul li span {
  display: inline-block;
  color: #fff;
  font-size: 17px;
}
.team-item.top-para {
  display: flex;
  padding: 39px;
}
.img-left img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin-right: 60px;
  box-shadow: 1px 1px 10px 4px #ccccccd6;
}
.detail-p {
  text-align: left;
  margin-top: 95px;
}

.detail-p h3 {
  font-size: 30px;
  color: #000;
  margin-bottom: 6px;
}
.detail-p span {
  font-size: 17px;
  font-weight: 200;
  margin-bottom: 16px;
  display: inline-block;
}
.boardmembers {
  width: 177px !important;
  height: 177px;
  object-fit: cover;
  margin-right: 15px;
  display: none;
}
.details-img {
  padding: 25px !important;
  display: flex;
  margin: 20px 0;
}
@media (max-width: 767px) {
  .page-title-area .title-item h2 {
    font-size: 46px;
  }
}
@media (max-width: 575px) {
  .page-title-area {
    height: 208px;
  }
  .details-img {
    padding: 25px !important;
    display: inherit;
    margin: 20px 0;
  }
  .tt {
    padding-top: 75px;
  }
  .page-title-area .title-item h2 {
    font-size: 35px;
    margin-bottom: 0px;
  }
  .bu-text h4 {
    font-size: 18px;
  }
  .details-item h2 {
    font-size: 19px !important;
    padding-bottom: 4px;
  }
  .post.widget-item h3 {
    padding-top: 8px;
    padding-left: 0px;
  }
  .align-items-center {
    position: relative;
    display: block;
    left: 0px;
    padding-left: 0px;
  }
  .post.widget-item img {
    width: 100%;
  }
  ul.align-items-center h4 {
    padding-left: 0px;
    padding-top: 10px;
  }
  h3.t-dt {
    font-size: 22px;
    padding-left: 0px;
  }
  .common-right-content.widget-item ul {
    padding-left: 0px;
  }
  .boardmembers {
    width: 100% !important;
    height: auto;
    object-fit: cover;
    margin-right: 0;
  }
}

@media (max-width: 425px) {
  .tt {
    padding-top: 55px !important;
  }
  .page-title-area .title-item h2 {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .page-title-area .title-item ul li span {
    font-size: 13px;
  }
  .page-title-area .title-item ul li {
    margin-left: 0px;
    font-size: 13px;
    margin-right: 23px;
  }
  .page-title-area .title-item ul li:before {
    left: 44px;
  }
}
@media (max-width: 320px) {
  .page-title-area {
    height: 160px !important;
  }
  .bu-text h4 {
    font-size: 16px;
  }
  .page-title-area .title-item h2 {
    font-size: 24px !important;
  }
  .details-item p {
    font-size: 15px !important;
  }

  .tt {
    padding-top: 40px !important;
  }

  .page-title-area .title-item ul li {
    margin-left: 0px;
    font-size: 13px;
    margin-right: 18px;
  }
  .page-title-area .title-item ul li span {
    font-size: 13px;
  }
  .page-title-area .title-item ul li:before {
    width: 2px;
    height: 2px;
    top: 10px;
    left: 42px;
  }
  .page-title-area .title-item ul li a {
    margin-left: -2px;
  }
  .details-item h2 {
    font-size: 15px !important;
    padding-bottom: 3px;
  }
  .details-item h5 {
    font-size: 16px;
  }
  ul.align-items-center a {
    font-size: 16px;
    color: #000;
  }
  h3.t-dt {
    font-size: 20px;
    padding-left: 0px;
  }
  .common-right-content ul li a {
    display: block;
    color: #302c51;
    font-size: 15px;
  }
}
