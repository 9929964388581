
section.gallery-area.pb-40 {
  margin-bottom: 28px;
}
.swiper {
  width: 100%;
  height: 100%;
}
section.logotext {
  padding: 23px 0px;
  background: #f5f5f5;
  margin: 0px 0px 0px 0px;
}
img.slit-img {
  width: 70%;
  /* height: 6%; */
}
/* img.slit-img {
  width: 140px;
  height: 80px;
} */

.sc-fotOHu.dyWJYy.rec.rec-pagination {
  display: none;
}
.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.pt-100 {
  padding-top: 20px;
}
.pb-70 {
  padding-bottom: 45px;
}
.pb-100 {
  padding-bottom: 100px;
}

.banner-area {
  height: auto;
  background-image: url(../../assests/image/banner/bannerbg2.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  height: 470px;
}
.img img.skill-img {
  width: 100%;
  object-fit: cover;
  height: 356px;
}
.banner-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #302c51;
  opacity: 0.8;
}

.banner-content {
  margin-top: 96px;
  max-width: 100%;
  position: relative;
  z-index: 1;
}
.banner-content span {
  display: inline-block;
  font-weight: 700;
  font-family: "Jost", sans-serif;
  color: #f2ba13;
  border-radius: 25px;
  padding: 12px 15px;
  font-size: 22px;
  margin-bottom: 0px;
}
.banner-content h1 {
  color: #fff;
  font-size: 52px;
  line-height: 54px;
  font-weight: 700;
  margin-bottom: 23px;
  font-family: "Jost", sans-serif;
}
.section-title h2 {
  color: #001234;
  font-size: 45px;
  line-height: 1.1;
  font-weight: 600;
  letter-spacing: -0.3px;
}

.common-btn {
  color: #fff;
  font-size: 13px;
  background-color: #f2ba13;
  display: inline-block;
  padding: 10px 25px;
  border-radius: 30px;
  font-weight: 600;
  text-decoration: none;
}
.common-btn:hover {
  color: #fff;
  background-color: #302c51;
  -webkit-transform: translate(0, -5px);
  transform: translate(0, -5px);
}
.feature-item.two h4 {
  font-size: 19px;
  color: #fff;
  font-weight: 800;
}
.feature-item.three h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 800;
  margin-bottom: 5px;
}
.feature-item h4 {
  font-size: 19px;
  color: #fff;
  margin-bottom: 6px;
  font-weight: 700;
  font-family: "Jost", sans-serif;
}
.section-title span.Where-text {
  font-size: 29px;
  font-weight: 700;
  color: #f2ba13;
}
.section-title-new span {
  font-size: 19px;
  font-weight: 600;
  color: #f2ba13;
}
.section-title-new h6 {
  font-size: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid #d1cbcb;
}

.sc-text {
  padding-left: 0px;
}
.about-area.pt-10.pb-50 {
  padding-top: 30px;
  background-color: #f5f5f5;
}
.feature-text span {
  font-weight: 800;
  font-size: 30px;
  color: white;
}
.feature-text {
  text-align: center;
}
.feature-text h4 {
  color: white;
  font-weight: 700;
  font-size: 23px;
  padding-bottom: 30px;
}
.section-title h6 {
  font-size: 27px;
  padding-bottom: 27px;
  text-align: center;
  border-bottom: 1px solid #e3dfdf;
}
.section-title .sub-title {
  display: inline-block;
  color: #f2ba13;
  margin-bottom: 7px;
  font-size: 18px;
  font-weight: 600;
}
.campus img {
  height: 324px;
  object-fit: cover;
  width: 100%;
}
.about-area .about-content {
  margin-bottom: 30px;
}
section.gallery-area.pb-40 p {
  padding-top: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 27px;
  padding-bottom: 20px;
}
.about-area .about-content .section-title {
  text-align: left;
  margin-bottom: -41px;
}
.about-area .about-content .about-span {
  display: block;
  color: #302c51;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 21px;
}
.about-area .about-content p {
  margin-bottom: 0px;
  font-size: 15px;
  color: #918a8a;
  line-height: 31px;
}
.about-area .about-content .about-btn-area {
  margin-top: 30px;
}
.about-area .about-content .about-btn-area .about-btn {
  background-color: #302c51;
  margin-right: 15px;
}
.about-area .about-content .about-btn-area .about-btn:hover {
  background-color: #f2ba13;
}
.feature-area {
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: auto;
  margin-top: -61px;
}

.feature-item.one{
  height: 260px;
  margin-bottom: 10px;
  background-color: #5aa411;
  border-radius: 30px 30px 0 30px !important;
  padding: 18px 30px 0px 30px;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.feature-item.two {
  height: 260px;
  margin-bottom: 10px;
  background-color: #f2ba13;
  border-radius: 30px 30px 0 30px !important;
  padding: 18px 30px 0px 30px;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.feature-item.three {
  height: 260px;
  margin-bottom: 10px;
  border-radius: 30px 30px 0 30px !important;
  padding: 18px 30px 0px 30px;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  background-color: #186268;
}
.feature-item:hover {
  border-radius: 0;
  -webkit-transform: translate(0, -10px);
  transform: translate(0, -10px);
}
.feature-item i {
  display: inline-block;
  color: #fff;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 5px;
}
.feature-item h3 {
  margin-bottom: 0px;
  font-size: 27px;
}
.feature-item h3 a {
  color: #fff;
  text-decoration: none;
}
.feature-item h3 a:hover {
  letter-spacing: 1px;
  color: #fff;
}
.feature-item p {
  color: #fff;
  font-size: 15px;
  font-weight: 200;
}
.feature-item .feature-btn {
  display: inline-block;
  color: #fff;
  font-weight: 600;
  border: 1px solid #fff;
  padding: 8px 25px;
  border-radius: 30px;
  text-decoration: none;
}
.feature-item .feature-btn:hover {
  color: #302c51;
  background-color: #fff;
}
.donation-item {
  min-height: auto;
  width: 100%;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 0 20px 0 #c7bebe99;
  box-shadow: 0 0 20px 0 #c7bebe99;
  border-radius: 30px 30px 0 30px;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.donation-item:hover {
  -webkit-transform: translate(0, -10px);
  transform: translate(0, -10px);
  border-radius: 0;
  background-color: #302c51;
}
.donation-item:hover .img img {
  border-radius: 0;
}
.donation-item:hover .img .common-btn {
  opacity: 1;
  visibility: visible;
  bottom: 15px;
}
.donation-item:hover .top .tags {
  color: #fff;
}
.donation-item:hover .top h3 {
  color: #fff !important;
}
.donation-item:hover .top h3 a:hover {
  color: #f2ba13;
}
.donation-item:hover .top p {
  color: #fff !important;
}
.donation-item:hover .bottom .skill:before {
  background: #fff;
}
.donation-item:hover .bottom ul li {
  color: #fff;
}
.donation-item:hover .bottom h4 {
  color: #fff;
}

.donation-item .img {
  position: relative;
}
.donation-item .img img {
  width: 100%;
  height: 356px;
  border-radius: 30px 30px 0 30px;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.donation-item .img .common-btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  max-width: 160px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  visibility: hidden;
}
.donation-item .inner {
  padding: 26px 30px 30px;
}
.donation-item .top .tags {
  display: inline-block;
  color: #f2ba13;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
  margin-bottom: 12px;
}
.donation-item .top .tags:hover {
  color: #f2ba13;
}
.donation-item .top h3 {
  color: #302c51;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
}
.donation-item .top h3 a:hover {
  color: #fff !important;
}
.donation-item .top h3 a:hover {
  color: #f2ba13;
}
.donation-item .top p {
  font-size: 13px;
  color: #000 !important;
  transition: 0.5s all ease;
}
.gallery-item {
  margin-bottom: 30px;
  position: relative;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.gallery-item:hover {
  -webkit-transform: translate(0, -10px);
  transform: translate(0, -10px);
}

.gallery-item:hover img {
  border-radius: 0;
}

.gallery-item:hover:before {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 0.8;
  visibility: visible;
  border-radius: 0;
}

.gallery-item:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #302c51;
  opacity: 0;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  visibility: hidden;
  border-radius: 30px 30px 0 30px;
}

.gallery-item img {
  width: 100% !important;
  border-radius: 30px 30px 0 30px;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.feature-area .feature-item p {
  font-weight: 400;
  color: #fff !important;

}
@media(max-width:1199px){
  .feature-item.one{
    height: 315px;
  }
  .feature-item.two{
    height: 315px;
  }
  .feature-item.three{
    height: 315px;
  }
}

@media (max-width: 1024px) {
  img.slit-img {
    width: 100%;
    /* height: 6%; */
  }
  .feature-item.three h4 {
    font-size: 17px;
  }
  .pt-100 {
    padding-top: 0px;
  }
  .pb-70 {
    padding-bottom: 25px;
  }
  .about-area .about-content p {
    line-height: 27px;
  }
}

@media (max-width: 991px) {
  .feature-item.one {
    height: 203px;
}
.feature-item.two {
  height: 178px;
}
.feature-item.three {
  height: 175px;
}
  .ant-image {
    display: block !important;
  }

  .about-area .about-content .section-title {
    margin-bottom: -19px;
  }

  .section-title h2 {
    margin-bottom: 20px !important;
    font-size: 36px !important;
  }
  .campus img {
    height: auto !important;
    object-fit: cover !important;
  }
  .donation-item .img img {
    height: auto;
  }

  .about-area .about-content {
    margin-bottom: 0px;
    margin-top: 15px;
  }
  .banner-content {
    margin-top: 50px;
  }
  .pt-100 {
    padding-top: 33px;
  }
  .pb-70 {
    padding-bottom: 0px;
  }
}
@media (max-width: 767px) {
  .feature-item.one {
    height: 227px;
}
.feature-item.three {
  height: 200px;
}
  .about-area .about-content .section-title {
    margin-bottom: 0px;
  }
  .feature-text h4 {
    font-size: 16px;
  }
  .feature-text span {
    font-size: 23px;
  }
  .feature-text {
    text-align: left;
  }
  .ant-image {
    width: 100%;
  }
  .section-title p {
    font-size: 16px;
  }
  .about-area .about-content .section-title h2 {
    font-size: 29px;
    margin-bottom: 0px !important;
  }
  .about-area .about-content p {
    font-size: 17px;
    color: #918a8a;
    line-height: 28px;
  }
  .section-title p {
    text-align: left;
  }
  .section-title {
    text-align: left;
  }
}

@media (max-width: 575px) {

  .banner-content span {
    margin-bottom: 13px;
  }
  .banner-content {
    margin-top: 40px;
    max-width: 100%;
  }
  .section-title .sub-title {
    margin-bottom: 0px;
    font-size: 16px;
  }
  .section-title {
    margin-bottom: 25px;
    margin-top: 0px;
  }

  .about-area .about-content .about-span {
    margin-bottom: 5px;
    font-size: 17px;
  }
  .about-area .about-content p {
    font-size: 15px;
    color: #918a8a;
    line-height: 24px;
    margin-bottom: 0px;
  }
  .about-area .about-content .about-btn-area {
    margin-top: 15px;
  }
  .pb-70 {
    padding-bottom: 10px;
  }
  .pt-100 {
    padding-top: 10px;
  }
  .feature-item h3 {
    font-size: 19px;
  }
  .feature-item p {
    color: #fff;
    font-size: 14px;
  }
  .benefit-item h3 {
    font-size: 19px;
    margin-bottom: 0px;
  }
  .benefit-item p {
    margin-bottom: 0;
    font-size: 14px !important;
  }
  .donation-item .top .tags {
    margin-bottom: 0px;
  }
}
@media (max-width: 525px) {
  .banner-content h1 {
    font-size: 37px;
  }
}
@media (max-width: 493px){
.feature-item.one {
    height: 246px;
}
.feature-item.two {
  height: 199px;
}
}
@media (max-width: 991px){
.feature-item.three {
    height: 219px;
}}

@media (max-width: 480px) {

  .banner-content h1 {
    font-size: 31px;
    line-height: 45px;
    margin-bottom: 10px;
  }
  .about-area .about-content .sc {
    margin-bottom: -14px !important;
  }
  .section-title h2 {
    font-size: 25px !important;
  }
  .section-title span.Where-text {
    font-size: 24px;
  }
  section.gallery-area.pb-70 p {
    padding-top: 0px;

    padding-bottom: 10px;
  }
  .banner-area {
    height: 400px;
  }
}
@media (max-width: 475px) {
  .banner-content span {
    font-size: 14px;
  }

  .about-area .about-content .about-span {
    font-size: 15px;
  }
  .about-area .about-content p {
    font-size: 14px;
  }
  .donation-item .top p {
    font-size: 15px;
  }
}
@media (max-width: 446px){
  .feature-item.two {
      height: 212px;
  }
  .feature-item.three {
    height: 230px;
  }
  .feature-item.one {
    height: 260px;
  }
  }
@media (max-width: 425px) {
  .feature-item.two {
    height: 224px;
}
  .pb-70 {
    padding-bottom: 5px;
  }
  section.gallery-area.pb-40 {
    margin-bottom: 0px;
  }
  section.gallery-area.pb-40 p {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .about-area .about-content .about-btn-area {
    margin-top: 8px;
  }

  section.gallery-area.pb-70 {
    margin-top: -20px;
  }
  .feature-item .feature-btn {
    padding: 4px 13px;
  }
  .banner-content h1 {
    font-size: 22px;
    line-height: 31px;
    margin-bottom: 15px;
  }
  .section-title h5 {
    font-size: 13px;
    line-height: 22px;
    padding-top: 8px;
  }

  .common-btn {
    font-size: 13px !important;
    padding: 8px 18px;
  }
  .section-title p {
    font-size: 13px;
  }
  .donation-item .top p {
    font-size: 13px;
  }
}
@media (max-width: 431px) {
  .common-btn {
    margin-top: 10px;
  }
  .dream-area h4 {
    padding-bottom: 0px;
    font-size: 16px !important;
  }
  .section-title-new h6 {
    font-size: 16px;
  }
  .section-title-new h5 {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .section-title-new span {
    font-size: 18px;
  }
}
@media (max-width: 400px){
.feature-item.one {
    height: 286px;
}
.feature-item.two {
  height: 236px;
}
.feature-item.three {
  height: 251px;
}
}

@media (max-width: 366px) {
  .common-btn {
    margin-bottom: 10px;
  }
}

@media (max-width: 375px) {
  .feature-item.one {
    height: 303px;
}
.feature-item.three {
  height: 249px;
}
  .donation-item .top p {
    font-size: 12px;
  }
  /* .feature-item {
    padding: 18px 5px 0px 20px;
} */
  .banner-content span {
    font-size: 14px;
  }
  .banner-area {
    height: 332px;
  }
  .common-btn {
    color: #fff;
    font-size: 13px;
  }
}

@media (max-width: 320px) {
  .feature-item.one {
    height: 354px;
}
.feature-item.two {
  height: 253px;
}
.feature-item.three {
  height: 272px;
}
  /* .feature-item {
    padding: 18px 11px 0px 20px;
    min-height: 257px !important;
} */

  .section-title h2 {
    font-size: 20px !important;
  }
  .banner-content h1 {
    font-size: 20px;
    line-height: 31px;
    margin-bottom: 12px;
  }
  .section-title span.Where-text {
    font-size: 21px;
  }
  .section-title-new span {
    font-size: 16px;
  }
  .banner-content span {
    font-size: 15px;
  }
  .banner-content {
    margin-top: 25px;
    max-width: 100%;
  }
  .feature-text span {
    font-size: 20px;
  }
  .donation-item .inner {
    padding: 11px 16px 30px;
  }

  .about-area .about-content .about-span {
    font-size: 14px;
  }
  .donation-item .bottom ul {
    margin-bottom: 0px;
    font-size: 13px;
  }
  .donation-item .bottom .skill {
    margin-bottom: 10px;
  }
  .donation-item .bottom h4 {
    font-size: 14px;
  }
  .donation-item .bottom h4 span {
    font-size: 13px;
  }

  .work-area .work-content ul li h3 {
    font-size: 15px;
  }
  .work-area .work-content ul li {
    margin-bottom: 27px;
  }
  .benefit-item h3 {
    font-size: 17px;
  }
  .gallery-item {
    margin-bottom: 0px;
  }
  .common-btn {
    margin-bottom: 0px;
  }
  .section-title-new h5 {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .section-title-new h6 {
    font-size: 14px;
  }

  .about-area .about-img {
    margin-bottom: 10px;
    position: relative;
  }
  .gallery-item {
    margin-bottom: 10px;
  }
}

@media (max-width: 320px) {
  .about-area .about-content .about-btn-area {
    margin-top: 9px;
    margin-bottom: 6px;
  }
  .about-area .about-content p {
    font-size: 13px;
  }
}
