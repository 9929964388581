.footer-area .copyright-area {
  margin-top: 20px;
  text-align: center;
  border-top: 1px solid #4f4b6b;
  padding-top: 25px;
  padding-bottom: 25px;
}
.footer-links {
  text-align: center;
}
.footer-area {
  background-color: #141032;
}
ul.ref-text {
  display: flex;
}
ul.ref-text h6 a {
  color: white;
  font-family: Verdana, Geneva, sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.footer-area {
  padding: 64px 30px 10px;
  background-color: #302c51;
}
.footer-area .copyright-area p {
  margin-bottom: 0;
  color: #fff;
  font-size: 16px;
}
.footer-area .copyright-area p a {
  display: inline-block;
  color: #ff6015;
  font-weight: 600;
  text-decoration: none;
}

.footer-area .copyright-area p a:hover {
  color: #fff;
}

.footer-item {
  margin-bottom: 30px;
}

.footer-item h3 {
  margin-bottom: 30px;
  color: #fff;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 20px;
}
.footer-item h3 {
  margin-bottom: 41px;
  color: #fff;
  margin-left: 32px;
  font-size: 22px;
}

.footer-item .footer-logo .logo {
  display: block;
  margin-bottom: 0px;
  margin-top: -22px;
}

.footer-item .footer-logo p {
  margin-bottom: 20px;
  color: #fff;
  font-size: 14px !important;
  line-height: 26px;
}
.footer-item .footer-logo ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 41px;
  margin-left: -28px;
}

.footer-item .footer-logo ul li:last-child {
  margin-right: 0;
}

.footer-item .footer-logo ul li a {
  width: 35px;
  height: 35px;
  text-align: center;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 14px;
  display: inline-block;
}

.footer-item .footer-logo ul li a i {
  line-height: 29px;
  font-size: 19px;
}

.footer-item .footer-logo ul li a:hover {
  color: #ff6015;
  background-color: #fff;
}
.footer-item .footer-links ul li {
  list-style-type: none;
  display: block;
  padding-bottom: 10px;
}

.footer-item .footer-links ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.footer-item .footer-links ul li a {
  display: block;
  font-family: Verdana, Geneva, sans-serif;
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  text-align: center;
}

.footer-item .footer-links ul li a:hover {
  color: #ff6015;
}

.footer-item .footer-links ul li a i {
  display: inline-block;
  font-size: 17px;
}
.footer-item .footer-contact .contact-inner:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.footer-item .footer-contact .contact-inner ul li {
  list-style-type: none;
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
}
.footer-item .footer-contact .contact-inner ul li i {
  display: inline-block;
  color: #fff;
  font-size: 17px;
  position: absolute;
  top: -1px;
  left: 0;
}

.footer-item .footer-contact .contact-inner ul li a {
  display: inline-block;
  color: #fff;
  font-family: Verdana, Geneva, sans-serif;
  font-weight: 400;
  text-decoration: none;
  font-size: 14px;
}

.footer-item .footer-contact .contact-inner ul li a:hover {
  color: #ff6015;
}
@media (max-width: 991px) {
  .footer-item h3 {
    margin-bottom: 18px;
    margin-left: 0px;
    font-size: 20px;
  }
  .footer-links {
    text-align: left;
}
.footer-item .footer-links ul li a {
  text-align: left;
}
  .footer-item {
    margin-bottom: 14px;
  }
  .footer-links ul {
    padding-left: 0px;
  }
  .footer-contact ul {
    padding-left: 0px;
  }
  .footer-item .footer-contact .contact-inner ul li a {
    font-size: 15px;
    padding-top: 6px;
  }
  .footer-item .footer-contact .contact-inner ul li {
    padding-left: 28px;
  }
}
@media (max-width: 768px) {
  .footer-area .copyright-area {
    margin-top: 0px;
  }
}
@media (max-width: 767px) {
  .footer-item .footer-contact .contact-inner ul li a {
    font-size: 15px;
    padding-top: 0px;
  }
  .footer-area .copyright-area {
    margin-top: 14px;
  }
  .footer-item .footer-contact .contact-inner ul li i {
    top: -7px;
  }
  .footer-area .copyright-area p {
    margin-bottom: 0;
    color: #fff;
    font-size: 15px;
  }
}
@media (max-width: 575px) {
  li.mb-3 {
    margin-bottom: 0px !important;
}
  .footer-contact ul {
    padding-left: 0px;
    margin-bottom: 0px;
}
  .footer-area {
    padding: 28px 8px 0px;
  }
  .footer-item .footer-logo ul li a i {
    line-height: 9px;
    font-size: 12px;
  }
  .footer-item .footer-logo ul li a {
    width: 27px;
    padding-bottom: 26px;
    height: 25px;
  }
  .footer-links ul {
    padding-left: 0px;
  }
  .footer-item {
    margin-bottom: 0px;
  }
  .footer-item .footer-logo ul li {
    margin-right: 34px;
    margin-bottom: -6px;
  }
  .footer-item .footer-logo .logo {
    display: block;
    margin-bottom: 0px;
  }
  .footer-item .footer-logo p {
    margin-bottom: 10px;
    color: #fff;
    font-size: 15px;
  }
  .footer-item h3 {
    margin-bottom: 10px;
    color: #fff;
    margin-left: 0px;
    font-size: 19px;
  }
  .footer-item .footer-contact .contact-inner {
    border-bottom: 1px solid #4f4b6b;
    padding-bottom: 15px;
    margin-left: 0px;
  }
  .footer-item .footer-contact .contact-inner ul li i {
    top: 8px;
    left: 0;
    font-size: 16px;
  }
  .footer-item .footer-contact .contact-inner ul li a {
    font-size: 14px;
    padding-left: 0px;
    padding-top: 10px;
  }
  /* .footer-item .footer-contact .contact-inner ul li {
    padding-left: 19px;
    padding-right: 0px;
  } */
  .footer-area .copyright-area p {
    font-size: 16px;
  }
  .footer-item .footer-contact .contact-inner:last-child {
    margin-bottom: 0px;
  }
}
@media (max-width: 412px) {
  .footer-item .footer-causes .cause-inner ul {
    display: flex;
  }
  .footer-item .footer-logo p {
    font-size: 14px;
  }
  ul.ref-text h6 a {
    font-size: 15px;
  }
}
@media (max-width: 345px) {
  .footer-item .footer-causes .cause-inner ul li h3 {
    padding-left: 40px;
  }
  .footer-item .footer-logo ul li {
    margin-right: 35px;
  }
}
@media (max-width: 320px) {
  .footer-area .copyright-area {
    padding-top: 15px;
    padding-bottom: 15px;
}
  .footer-item .footer-contact .contact-inner ul li {
    margin-bottom: 0px;
  }
  .footer-item .footer-contact .contact-inner ul li a {
    font-size: 14px;
    padding-left: 0px;
  }
  .footer-item .footer-contact .contact-inner ul li i {
    top: 8px;
    left: 0;
    font-size: 16px;
  }
  .footer-item .footer-logo ul li a i {
    line-height: 20px;
    font-size: 12px;
  }
  .footer-item .footer-logo ul li a {
    width: 27px;
    height: 25px;
  }
  .footer-area {
    padding: 41px 0px 0px;
  }
  .footer-area .copyright-area p {
    font-size: 15px;
  }
  .footer-logo img {
    width: 72px;
  }
}
@media (max-width: 425px) {
  .footer-item .footer-causes .cause-inner ul li img {
    border-radius: 5px;
    width: 100%;
  }
  .footer-item h3 {
    margin-bottom: 0px;
}
}
@media (max-width: 320px) {
  .footer-item .footer-causes .cause-inner {
    padding-bottom: 0px;
  }
}
