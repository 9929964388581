.title-bg-two {
	background-image: url(../../assests/image/banner/titlebg8.jpg)
}
.title-item h3 {
    font-size: 47px !important;
    color: #fff !important;
    margin-bottom: 0px !important;
}
.page-link {
    position: relative;
    display: block;
    color: #f50a4b;
    border: navajowhite;
    text-decoration: none;
    background-color: #fff;
}
li.page-item{
	display: block;
    color: #ff6015;
    text-decoration: none;
    background-color: #fff;
    -webkit-box-shadow: 0 0 20px 0 #dddddd8c;
    box-shadow: 0 0 20px 0 #dddddd8c;
    padding: 0px 2px;
	border-radius: 8px 8px 0 8px;
	margin-left: 6px;	
}
.page-item.active .page-link {
    z-index: 3;
	color: #fff;
	padding: 9px 15px;
	border-radius: 8px 8px 0 8px;
    background-color: #ff6015;
    border-color: #ff6015;
}
/*********************donation details***************/
.page-title-area.title-bg-ty1 {
    padding-top: 100px;
}
.title-bg-ty1 {
	background-image: url(../../assests/image/banner/titlebg8.jpg)
}
.title-item.t5 {
    padding-top: 100px;
}
.donation-details-area .details-item  blockquote {
    background-color: #f9f9f9;
    padding: 30px 30px 30px 60px;
    font-size: 20px;
    font-weight: 600;
    color: #302c51;
    position: relative;
    margin-left: 35px;
    margin-top: 30px;
    margin-bottom: 25px;
}
.details-item p {
    font-size: 19px;
}
.details-item h2.sc-gsDKAQ.djZNKz {
    font-size: 28px;
}
.details-item h3{
    font-size: 26px;
    font-weight: 700;
    color: #000;
}
.donation-details-area .details-item  blockquote i {
    position: absolute;
    top: 45px;
    left: -35px;
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    background-color: #fff;
    color: #ff6015;
    -webkit-box-shadow: 0 0 20px 0 #dddddd8c;
    box-shadow: 0 0 20px 0 #dddddd8c;
    font-size: 30px;
    border-radius: 50%;
}
.details-share .left ul li {
    list-style-type: none;
    display: inline-block;
    margin-right: 3px;
}
 .details-share .left ul li span {
    display: inline-block;
    font-weight: 600;
	margin-right: 5px;
	font-size: 23px;
	padding-left: 0px;
}
.details-payment h3{
    font-size: 25px;
   padding-bottom: 0px;
}
.details-share .left ul li a i {
    line-height: 20px;
}
 .details-share .left ul li a {
    display: block;
    width: 35px;
    height: 35px;
    text-align: center;
    color: #ff6015;
    border: 1px solid #ff6015;
    border-radius: 50%;
    font-size: 17px;
}
 .details-share .right {
    text-align: right;
}
 .details-share .right ul li {
    list-style-type: none;
    display: inline-block;
    margin-right: 10px;
    position: relative;
}
 .details-share .right ul li span {
    display: inline-block;
	font-weight: 600;
	font-size: 22px;
}
.details-share .right ul li:before {
    position: absolute;
    content: ',';
    color: #ff6015;
    top: -5px;
    right: -7px;
    font-size: 20px;
}
.details-share .right ul li a {
    display: inline-block;
	color: #ff6015;
	font-size: 17px;
}
.widget-area .widget-item {
	margin-bottom: 30px
}

.widget-area .widget-item:last-child {
	margin-bottom: 0
}

.widget-area .widget-item h3 {
	font-size: 22px;
	margin-bottom: 20px
}

.widget-area .search {
	position: relative;
	left: 24px;
}

.widget-area .search .form-control {
	height: 50px;
	border-radius: 5px;
	padding-left: 15px;
	font-size: 15px;
	border: 0;
	background-color: #f9f9f9
}

.widget-area .search .form-control:focus {
	border: 0;
	-webkit-box-shadow: none;
	box-shadow: none
}

.widget-area .search .btn {
	padding: 0;
	position: absolute;
	background-color: transparent;
	top: 0px;
	right: 15px;
	font-size: 20px;
	border: none;
	color: #ff6015;
	-webkit-transition: .5s all ease;
	transition: .5s all ease
}

.widget-area .search .btn:hover {
	color: #302c51
}
.align-items-center {
    display: flex;
    align-items: center !important;
    list-style-type: none;
}
ul.align-items-center a {
    font-size: 18px;
    color: #000;
}
 .common-right-content ul li {
    list-style-type: none;
    display: block;
    border-bottom: 1px solid #f5f2f2;
    position: relative;
    padding-left: 15px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    -webkit-transition: .5s all ease;
    transition: .5s all ease;
}
 .common-right-content ul li:before {
    position: absolute;
    content: '';
    top: 12px;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ff6015;
}
.common-right-content ul li a {
    display: block;
	color: #302c51;
	font-size: 17px;
}
h3.t-dt {
    font-size: 22px;
    padding-left: 27px;
}
 .instagram .instagram-item {
    position: relative;
}
 .instagram .instagram-item:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #302c51;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(.5);
    transform: scale(.5);
    -webkit-transition: .5s all ease;
    transition: .5s all ease;
}
.instagram .instagram-item img {
    width: 100%;
}
 .instagram .instagram-item a {
    display: inline-block;
    color: #fff;
    font-size: 35px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -webkit-transition: .5s all ease;
    transition: .5s all ease;
    opacity: 0;
    visibility: hidden;
}
.instagram.widget-item {
    padding-left: 32px;
}
.instagram.widget-item h3 {
    font-size: 23px;
}
  .details-payment {
    background-color: #fafafa;
    padding: 20px;
}
.details-payment input.ant-input.form-control {
	margin-bottom: 12px;
	background-color: #fff;
}
button.ant-btn.ant-btn-submit {
    width: 100%;
    background-color: #ff6015;
    color: #fff;
    font-size: 20px;
    height: 50px;
    padding: 5px;
    border: none;
    border-radius: 30px;
}

ul.align-items-center h4 {
    padding-left: 17px;
}
ul.align-items-center p{
    padding-left: 20px;
    font-size: 16px;
}
.post.widget-item h3 {
    padding-top: 8px;
    padding-left: 26px;
}
button.ant-btn.ant-btn-submit.btn i {
    position: absolute;
    right: 4px;
    bottom: 12px !important;
}
.ant-input-affix-wrapper > input.ant-input {
    height: 35px;
    padding: 0;
    border: none !important;
    outline: none;
}
.ant-input-prefix {
    color: red;
    margin-right: 12px;
}
.ant-input-affix-wrapper {
	border: none;
} 
 .instagram .instagram-item:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #302c51;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(.5);
    transform: scale(.5);
    -webkit-transition: .5s all ease;
    transition: .5s all ease;
}
@media(max-width:991px){
    .widget-area .search {
        position: relative;
        left: 0px;
    }
    .details-payment {
        margin-bottom: 10px;
    }
}
@media(max-width:767px){
    .widget-area .search {
        position: relative;
        left: 0px;
    }
}
@media(max-width:575px){
	.page-title-area {
	  height: 208px;
  }
  .title-item h3 {
    font-size: 30px !important;
    
}
  .details-share .right {
    text-align: left;
}
.page-title-area.title-bg-ty1 {
    padding-top: 56px;
}
  }
 
@media(max-width:425px){
    .tt {
		padding-top: 55px !important;
	}
    .details-payment h3 {
        font-size: 17px!important;
    }
    .details-payment {
      
        padding: 15px;
    }
    span.ant-radio + * {
        font-size: 14px;
    }
    button.ant-btn.ant-btn-submit {
        font-size: 18px;
    }
    .donation-details-area .details-item  blockquote i {
       
        top: 0px;
        left: -35px;
        width: 50px;
        height: 50px;
        line-height: 46px;
        font-size: 14px;
    }
    .donation-details-area .details-item  blockquote {
        padding: 13px 10px 17px 21px;
        font-size: 16px;
        margin-left: 35px;
        margin-top: 0px;
        margin-bottom: 5px;
    }
}
@media(max-width:320px){
	.page-title-area {
		height: 160px !important;
    }
    .title-item h3 {
        font-size: 20px !important;
    }
    .page-title-area.title-bg-ty1 {
        padding-top: 41px;
    }
}