
a.emp {
  margin-top: -20px;
}
img.logo-header {
  position: relative;
  top: 0px;
  width: 78px;
  left: 0px;
  float: left;
  height: 78px;
}
.navbar {
  padding: 0px;
  margin-bottom: -6px;
  margin-top: -6px;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
/* .navbar {
  box-shadow: rgb(0 0 0 / 10%) 0 9px 4px;
} */
img.home-nav {
  width: 28px;
}
a.bpnt {
  background-color: #9f3a16;
  border-radius: 33px;
  border: none;
  line-height: 35px;
  font-size: 12px;
  color: #fff;
  height: 36px;
  text-align: center;
  width: 110px;
}

img.logo-img {
  display: none;
}
.side-nav {
  display: none;
}
.nav-bt {
  position: relative;
  bottom: 0px;
  right: 7px;
  display: none;
}
nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

nav ul li {
  display: inline-block;
}
nav a {
  display: block;
  font-family: Verdana, Geneva, sans-serif;
  padding: 0 15px;
  font-weight: 500;
  color: #000;
  font-size: 15px;
  line-height: 48px;
  text-decoration: none;
}
nav li:hover {
  color: #000 !important;
}
nav ul a:hover{
  color: #000 !important;
}
nav ul li a:hover {
  color: #000!important;
}
nav ul ul li a:hover {
  color: #fff!important;
}
nav a:hover {
  color: #000 !important;
}
nav ul ul {
  background-color: #302c51;
  display: none;
  position: absolute;
  width: 260px;

  top: 49px; /* the height of the main nav */
}
nav ul li:hover > ul {
  display: inherit;
}

nav ul ul li {
  font-size: 14px;
  border-bottom: 1px solid #adacac;
  float: none;
  text-align: left !important;
  display: list-item;
  position: relative;
}
nav ul ul ul {
  width: 259px;
  background-color: #302c51;
  position: absolute;
  left: 261px;
  top: 24px;
}
nav ul ul ul li {
  border-bottom: 1px solid #adacac;
}
nav ul ul a {
  color: #fff;
}
nav ul ul ul a {
  color: #fff;
}
li > a:after {
  content: "";
}
li > a:only-child:after {
  content: "";
}

@media(max-width:1282px){
  nav a {
    padding: 0 10px;
    font-size: 13px;
}
}
@media (max-width: 1199px) {
  nav a {
    padding: 0 10px;
  }
}
@media (max-width: 1099px) {
  nav a {
    padding: 0 8px;
  }
}
@media (max-width: 1059px) {
  nav a {
    padding: 0 6px;
  }
 
}
@media (max-width: 1009px) {
  nav a {
    font-size: 13px;
  }
}
@media (max-width: 991px) {
  
  nav ul ul ul {
    z-index: 9;
  }
  nav ul li {
    border-bottom: 1px solid #e7e3e3;
    display: block;
  }
  .navbar-toggler {
    float: right;
    background-color: #f9f9f9;
    border: none;
  }
  img.logo-header {
    display: block;
  }
  .nav-bt {
  
    top: 22px;
    position: absolute;
    bottom: 0px;
    right: 50px;
}
  .navbar-toggler:focus {
    box-shadow: none !important;
  }
  form#donateForm {
    margin-top: -12px;
}
}
@media (max-width: 511px) {
  nav ul ul ul {
    width: 220px;
    left: 178px;
    top: 49px;
  }
}
@media (max-width: 511px) {
  nav ul ul ul {
    left: 131px;
  }
 
}
@media(max-width:425px){
  a.bpnt {
    line-height: 30px;
    font-size: 11px;
    height: 32px;
    width: 96px;
  }
  .nav-bt {
    top: 25px;
  }
}
@media (max-width: 354px) {
  nav ul ul ul {
    left: 84px;
    top: 98px;
  }
}
@media (max-width: 311px) {
  nav ul ul ul {
    left: 66px;
  }
}
