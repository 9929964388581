.press-img img {
  width: 100%;
}
.press-text h3 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #d16a6a;
}

.describ-text h3 {
  font-size: 30px;
  padding-bottom: 10px;
  color: #000;
}
.press-1 {
  padding: 0px 0px;
}
.press-text p {
  font-size: 15px;
  font-weight: 500;
  color: #897d7d;
}
.press-1 h4 {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 700;
  color: #000;
}
.press-1 p {
  font-size: 16px;
  font-weight: 400;
  color: #595353;
  margin-bottom: 7px;
}
section.press-section {
  padding-top: 35px;
  padding-bottom: 30px;
}
a.press-view {
  overflow: hidden;
  border: none;
  color: white;
  background: #f2ba13;
  font-size: 14px;
  font-weight: 600;
  line-height: 3;
  /* font-family: "Karla", sans-serif; */
  padding: 0px 33px 0px;
  display: inline-block;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  text-transform: capitalize;
  transition: all 0.4s ease-in-out;
  height: 45px;
}
section.ress-descript {
  padding-top: 50px;
  padding-bottom: 50px;
}
.press-img {
  padding-top: 10px;
}