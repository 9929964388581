body{
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Lexend', sans-serif !important;
  font-weight: 300 !important;
}

.annu-1 h1 {
  margin-bottom: 0px;
  font-size: 31px;
  font-weight: 500;
  color: #a31111;
}
svg.pdf {
  margin-left: 30px;
}
.annu-1 p {
  font-size: 15px;
  line-height: 21px;
  color: #898282;
  font-weight: 500;
}
section.annu-text {
  padding-top: 20px;
  padding-bottom: 25px;
}
.about-img__thumb img {
  width: 100%;
}
.section-title {
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
section.focus-work {
    padding-top: 19px;
    background-color: #fafafa;
    padding-bottom: 19px;
}
button.theme_btn.theme_btn_bg.btn.btn-primary{
  overflow: hidden;
    border: none;
    color: white;
    background: #f2ba13;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    
    padding: 0px 49px 0px 23px;
    display: inline-block;
    border-radius: 30px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    text-transform: uppercase;
    transition: all 0.4s ease-in-out;
    height: 50px;
}

.theme_btn span {
  position: absolute;
  right: 12px;
  background: white;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
  top: 12px;
  display: inline-block;
  z-index: 3;
}

.theme_btn::before {
  top: 0px;
  width: 0px;
  left: auto;
  right: 0px;
  z-index: -1;
  bottom: 0px;
  content: "";
  position: absolute;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
}
.theme_btn:hover {
  color: white;
}
.theme_btn:hover::before {
  left: 0px;
  width: 100%;
  right: auto;
}
.theme_btn:hover i {
  color: #5aa411;
  transform: rotateY(180deg);
}

.theme_btn_bg::before {
  background: #5aa411 !important;
}

.theme_btn_bg_02::before {
  background: #f2ba13 !important;
}

.theme-border-btn {
  color: white;
  background: transparent;
  border: 2px solid #414657;
}
.theme-border-btn:hover {
  color: #001234;
  border-color: transparent;
}
.theme-border-btn:hover::before {
  background: #f2ba13;
}

.theme_btn2 {
  background: #5aa411 !important;
}
.theme_btn2 span i {
  color: #5aa411;
}
.theme_btn2:hover {
  border-color: transparent;
  background: transparent;
}
.theme_btn2:hover span i {
  color: #f2ba13;
}

.theme_btn3 {
  color: #001234 !important;
  background: white !important;
}
.theme_btn3 span {
  background: #f15b43;
}
.main-head{
  font-size: 28px;
  font-weight: 700;
  color: #000000;
  padding-bottom: 10px;
  border-bottom: 1px solid #f1efef;
  margin-top: 20px;
  margin-bottom: 0px;
}

form#donateForm a {
  overflow: hidden;
  border: none;
  color: white;
  background: #f2ba13;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  display: inline-block;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.4s ease-in-out;
  height: 50px;
}
.PaymentButton--rzpTheme.svelte-ekc7fv.svelte-ekc7fv::before{
  display: none;
}
form#donateForm svg.svelte-ekc7fv.svelte-ekc7fv{
  display: none !important;
}
.PaymentButton--rzpTheme.svelte-ekc7fv .PaymentButton-contents.svelte-ekc7fv{
  padding-left: 20px;
}
.PaymentButton-securedBy.svelte-ekc7fv {
  display: none;
}
.PaymentButton-text.svelte-ekc7fv.svelte-ekc7fv {
  display: block;
  /* min-height: 18px; */
  line-height: 35px;
  font-size: 14px;
  font-weight: 800;
  opacity: 1;
  text-transform: initial;
}

.press-view {
    overflow: hidden;
    border: none;
    color: white;
    background: #f2ba13;
    font-size: 14px;
    font-weight: 600;
    line-height: 3;
    /* font-family: "Karla", sans-serif; */
    padding: 0px 33px 0px;
    display: inline-block;
    border-radius: 30px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    text-transform: capitalize;
    transition: all 0.4s ease-in-out;
    height: 45px;
}
p{
  /* color: rgba(0, 0, 0, 0.85) !important; */
    font-size: 14px!important;
    font-weight: 300;
}
.PaymentButton-contents {
  padding: 10px !important;
  line-height: 24px !important;
  margin-top: 5px !important;
  font-style: normal;
}

@media(max-width:575px){
  .section-title {
    text-align: left;
}
}

@media(max-width:425px){
  .section-title h2 {
    font-size: 21px;
}
.main-head {
  font-size: 22px;
}
button.theme_btn.theme_btn_bg.btn.btn-primary {
  font-size: 11px;
  padding: 13px 35px 11px 14px;
}
.theme_btn span {
  right: 9px;
  width: 20px;
  height: 20px;
 
}
}
@media(max-width:320px){
  .main-head {
    font-size: 18px;
    padding-bottom: 3px;
}
}