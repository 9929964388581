.title-bg-ty {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  background-image: url(../../assests/image/banner/titlebg8.jpg) !important;
}
.tt {
  padding-top: 106px;
}
.page-title-area {
  height: 300px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.page-title-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #302c51;
  opacity: 0.75;
}

.page-title-area .title-item {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-top: 10px;
}
.contact-info-area.pt-100.pb-60 {
  padding-bottom: 30px;
  padding-top: 62px;
}
.page-title-area .title-item ul li {
  list-style-type: none;
  display: inline-block;
  margin-left: 8px;
  font-size: 17px;
  text-decoration: none;
  margin-right: 28px !important;
  position: relative;
}

.page-title-area .title-item ul li:before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  top: 10px;
  left: 60px;
  background-color: #fff;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  -webkit-animation: rotate360 1s infinite linear;
  animation: rotate360 1s infinite linear;
  border: 2px solid #f2ba13;
}

.page-title-area .title-item ul li:last-child:before {
  display: none;
}

.page-title-area .title-item ul li a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
}

.page-title-area .title-item ul li a:hover {
  color: #f2ba13;
}

.page-title-area .title-item ul li span {
  display: inline-block;
  color: #fff;
  font-size: 17px;
}

.d-flex {
  display: flex !important;
}

.contacts__text {
  padding-top: 22px;
}
.fo-text h1 {
  text-align: left;
  padding-left: 21px;
  font-size: 34px;
}
.contacts__icon {
  color: #f2ba13;
  font-size: 27px;
}
.mr-25 {
  margin-right: 20px;
}
.semi-02-title {
  margin-bottom: 0px;
  font-size: 16px !important;

  font-weight: 700;
  color: #000 !important;
}
svg.messge-icon {
  position: relative !important;
  bottom: 27px !important;
}
.fo-text h5 {
  color: #f2ba13;
  padding-left: 21px;
  font-weight: 700;
  font-size: 16px;
}
.contacts__text h5 {
  color: #777777;
  font-size: 14px;
  font-weight: 400;
}
.cnt p {
  font-size: 15px;
  color: #777777;
}
.cnt span.sc-pVTFL.ksNdlJ.sub-title {
  font-size: 21px;
  font-weight: 700;
  color: #f2ba13;
}
.cnt h1 {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.fo-text {
  width: 100%;
  background-color: #e3e3e3;
  padding: 15px 15px 20px 15px;
  margin-bottom: 30px;
}
button.btn1-primary.btn.btn-primary {
  outline: none;
  margin-top: 10px;
  width: 100%;
  border: none;
  border-radius: 30px;
  padding: 10px;
  background-color: #f2ba13;
}

.form-control {
  height: 50px;
  border-radius: 0px;
  background-color: #f9f9f9;
  /* padding-left: 40px; */
  font-size: 13px;
  border: 0;
}

@media (max-width: 575px) {
  .page-title-area {
    height: 208px;
  }
}
@media (max-width: 425px) {
  .tt {
    padding-top: 55px !important;
  }
}
@media (max-width: 425px) {
  .section-title1 {
    padding-bottom: 0px;
  }
  .section-title1 span.sub-title {
    font-size: 23px;
  }
  .section-title1 p {
    font-size: 16px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .fo-text {
    padding: 25px 10px 22px 10px;
    margin-bottom: 15px;
  }
}
@media (max-width: 320px) {
  .page-title-area {
    height: 160px !important;
  }
}
