.modal-content {
  border: none !important;
  background-color: transparent !important;
}
p.chohan-text {
  font-size: 13px !important;
  color: #746d6d;
  font-weight: 400;
  font-family: unset;
}
.light1box.mb-3 img {
  border-top: 4px solid #f2ba13;
  box-shadow: 0 0 20px 0 #dddddd8c;
  transition: 0.5s all ease;
  background-color: #fff;
  margin-bottom: 15px !important;
  padding: 10px;
}
.light1box.mb-1 img {
  border-top: 4px solid #f2ba13;
  box-shadow: 0 0 20px 0 #dddddd8c;
  transition: 0.5s all ease;
  background-color: #fff;
  margin-bottom: 5px !important;
  padding: 10px;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999 !important;
}
.carousel.carousel-slider .control-arrow {
  display: block !important;
}
.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}
.carousel .control-arrow {
  top: 0px !important;
  margin-top: -78% !important;
}
.ght img {
  width: 100%;
}
.team-member img {
  width: 100%;
}
.tt_en {
  padding-top: 76px;
}
a.trnav-text33.nav-link {
  /* text-align: center; */
  color: #fff;
  border: 1px solid;
  display: inline-flex;
  justify-content: center;
  width: 234px;
  margin: 15px auto 0;
  border-radius: 56px;
}
.team-member a.viw-port.nav-link {
  color: #fff;
  position: relative;
  width: 144px;
  border-radius: 0px 0px 36px 0px;
  background: #0b8310;
}
.mem {
  display: flex;
  position: relative;
}
.train-text img {
  width: 100%;
}
section.training-text {
  padding-top: 30px;
  padding-bottom: 30px;
}

.train1-taxt h2 {
  font-size: 19px;
  font-weight: 700;
  color: #262525;
  margin-bottom: 10px;
  padding-top: 0px;
  line-height: 33px;
}
.trd-text {
  padding: 10px 10px 10px 19px;
}
.train1-taxt p {
  font-size: 14px !important;
  font-weight: 500;
  color: #000;
}
.train1-taxt h5 {
  color: #f2ba13;
  font-size: 15px;
  font-weight: 700;
}
.train2-taxt img {
  width: 90px;
  border-radius: 50%;
  height: 90px;
  object-fit: cover;
  border: 5px solid #cbc5c5;
}
.train2-taxt {
  top: 25px;
  left: 10px;
  position: relative;
}

.train3-taxt p {
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #9f9595;
  padding-top: 19px;
}
.team-section.-text {
  text-align: center;
}
.modal-body {
  padding: 0px !important;
}
.sky-img img {
  width: 100% !important;
}
.section-team .single-person {
  margin-top: 0px;
  padding: 30px;
  background-color: #dee1e7;
  border-radius: 5px;
}
.section-team .single-person:hover {
  background: linear-gradient(to right, #f2ba13, #4b1e04);
}

.section-team .single-person .person-image {
  position: relative;
  margin-bottom: 50px;
  border-radius: 50%;
  border: 4px dashed transparent;
  transition: padding 0.3s;
}
section.section-team {
  padding-top: 0px;
  padding-bottom: 40px;
}
section.compte-text {
  padding-top: 14px;
  padding-bottom: 30px;
}
.section-team .single-person:hover .person-image {
  padding: 12px;
  border: 4px dashed #fff;
}
.section-team .single-person .person-image img {
  width: 100%;
  border-radius: 50%;
}

.person-info {
  text-align: center;
  padding-bottom: 20px;
}
.section-team .single-person .person-image .icon {
  position: absolute;
  bottom: 0;
  left: 50%;
  font-size: 21px;
  transform: translate(-50%, 50%);
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 43px;
  text-align: center;
  background-color: #1c2556;
  color: #fff;
  border-radius: 50%;
}
.section-team .single-person:hover .person-image .icon {
  background: none;
  background-color: #fff;
  color: #016cec;
}

.section-team .single-person .person-info .full-name {
  margin-bottom: 3px;
  font-size: 22px;
  font-weight: 700;
}

.section-team .single-person .person-info .speciality {
  font-size: 14px;
  color: #f2ba13;
}

.section-team .single-person:hover .full-name,
.section-team .single-person:hover .speciality {
  color: #fff;
}
.trade-text img {
  width: 100%;
}
.trade5-taxt h2 {
  font-weight: 700;
  font-size: 19px;
  margin-bottom: -5px;
}
section.tf-traxt {
  padding-top: 54px;
  padding-bottom: 10px;
}
.trade5-taxt p {
  font-size: 15px;
  color: #858181;
}
.trade-text h6 {
  font-weight: 700;
  font-size: 17px;
  color: #000;
  margin-bottom: 15px;
}
.trade7-text {
  margin-top: -28px;
}
.trade7-text p {
  font-size: 13px;
  color: #858181;
}
a.trnav-text22.nav-link {
  color: #fff;
  border-radius: 16px;
  position: relative;
  right: 32%;
  float: right;
  text-align: center;
  border: 1px solid #fff;
}
.trasd9-text p {
  width: 100%;
  font-size: 14px;
  color: #fff;
  text-align: center;
  font-weight: 700;
  padding: 5px 16px 0px 21px;
}
.trasd9-text h3 {
  font-size: 27px;
  font-weight: 800;
  color: #fff;
  text-align: center;
  padding-top: 5px;
  margin-bottom: 0px;
}
.trasd9-text span {
  bottom: 23px;
  right: 21px;
  position: absolute;
  border-radius: 10px;
  border: 1px solid #ebdede;
  background-color: #ebebeb;
}
a.trnav-text.nav-link {
  font-size: 13px;
  color: #000;
}
.mdk {
  padding: 27px;
}
.team-member {
  border: 2px solid #0b8310;
  box-shadow: 0 0 50px #ccc;
  border-radius: 0px 0px 47px 0px;
  width: 100%;
  background-color: #fff;
  padding-bottom: 26px;
}
.mem h4 {
  font-size: 14px;
  color: #000;
  position: absolute;
  font-weight: 600;
  right: 6px;
  padding: 12px 12px;
}
.mdk h3 {
  color: #fff;
  padding-top: 12px;
}
.mdk h6 {
  color: #fff;
}
.mdk span {
  color: #fff;
}
.mdk p {
  color: #fff9f9;
}
.mdk ul {
  padding-left: 0px;
  color: #fff;
}
.mdk li {
  padding-left: 0px;
  color: #fff;
}
p.mdol-text {
  padding: 20px 0px;
  font-size: 15px !important;
  color: #000;
}
.sk-text img {
  width: 100%;
}
.live7-text p {
  font-size: 14px;
  margin-bottom: 0px;
  padding-top: 20px;
}
.head-text {
  padding-left: 32px;
  padding-top: 17px;
}
.hcare1-text img {
  width: 100%;
}
.education-2 {
  padding-top: 0px;
}
.education-2 h2 {
  font-size: 28px;
  font-weight: bold;
  color: #ffc107;
  margin-bottom: 0px;
}
button#controlled-tab-example-tab-Achievements {
  border-radius: 0px;
  border: 1px solid #eae3e3;
}
button#controlled-tab-example-tab-CourseTeachers {
  border-radius: 0px;
  border: 1px solid #eae3e3;
}
button#controlled-tab-example-tab-FeesStructure {
  border-radius: 0px;
  border: 1px solid #eae3e3;
}
.d-health h2 {
  text-align: center;
  font-weight: 700;
  margin-bottom: 0px;
  font-size: 17px;
  color: #f2ba13;
}

.line {
  border: 1px solid #ba5f5f;
  width: 52px;
  text-align: center;
  margin-bottom: 34px !important;
  display: block;
  margin: 0 auto;
  align-items: center;
}
.d-health h6 {
  text-align: center;

  font-size: 33px;
  padding-bottom: 0px;
  margin-bottom: 5px;
  padding-top: 0px;
  font-weight: 600;
  color: #000;
}
section.lihood {
  margin-bottom: 45px;
}
.nav-tabs .nav-link {
  color: #000;
  margin-bottom: -1px;
  font-size: 13px !important;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  font-weight: 600;
}

.course-text p {
  font-size: 13px;
  padding-top: 9px;
  color: #938b8b;
}
.course-text h5 {
  color: #000;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0px;
}
.award-text img {
  margin: 0 auto;
}
.award-text1 img {
  margin-top: 10px;
  width: 100%;
}
.award-text1 h6 {
  font-size: 12px;
  font-weight: 600;
  padding-top: 12px;
  color: #8f8484;
}

h5.cert-text {
  text-align: left;
  font-size: 15px;
  color: #f2ba13;
  font-weight: 700;
  margin-bottom: 26px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d8cdcd;
}

.award-text p {
  text-align: center;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}
.award-text {
  width: 100%;
  display: grid;
  background: #fff;
  padding: 10px;
  box-shadow: 1px 1px 5px 3px #cccccc6e;
  margin-top: 20px;
  border-radius: 6px;
}
.award-text h6 {
  text-align: center;
  font-size: 25px;
  margin-bottom: 0px;
  font-weight: 600;
  color: #5aa411;
  padding: 15px 0 7px;
}
.cert-text {
  color: #5aa411;
}
.course-info-list > li {
  clear: both;
  margin-top: 10px;
}
.course-info-title {
  color: #555555;
  float: left;
  font-size: 12px;
  font-weight: 600;
  width: 31%;
}
.act img {
  width: 100%;
}
.text-theme-colored2 {
  margin-right: 12px;
  color: #f2ba13 !important;
}
.course-text ul {
  list-style: none;
  padding-left: 0px;
  /* margin-left: -1px; */
}
.education-2 p {
  margin-bottom: 0px;
  padding-top: 9px;
  font-size: 14px;
  color: #000000;
}
section.eduction-1 {
  padding-top: 0px;
}
.section-title-new h2 {
  padding-top: 0px;
  font-weight: 600;
  font-size: 21px;
  line-height: 30px;
  color: #000;

  margin-bottom: 0px;
}
.live-text img {
  width: 100%;
  margin-top: 40px;
}
.live1-text img {
  width: 100%;
}
.live4-text img {
  width: 100%;
}
.live2-text img {
  width: 100%;
}
.live1-text h3 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  padding-bottom: 10px;
}
.live2-text h3 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  padding-bottom: 10px;
}
.live5-text h6 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  padding: 14px 0px;
}

a.drs-text.nav-link {
  padding: 0px;
}
.swiper {
  width: 100%;
  height: 100%;
}
.slider-text img {
  width: 100%;
  object-fit: cover;
  height: 490px;
}
.eduction-text img {
  width: 100%;
}

.empt-text h6 {
  font-size: 52px !important;
  color: #fff;
  margin-bottom: 5px;
  padding-top: 19px;
}
.envire img {
  width: 100%;
  margin-bottom: 18px;
}
.lt img {
  width: 100%;
}
.bottom p {
  font-size: 14px;
}
.empt-text h4 {
  font-size: 47px !important;
  color: #fff;
  margin-bottom: 0px;
  padding-top: 19px;
}
img.praga {
  width: 100%;
  padding: 20px;
}

.h-text {
  margin-top: 0px;
  padding-bottom: 17px;
}
.sc-text p {
  font-size: 14px;
  color: #978f8f;
}

.h-text p {
  font-size: 15px;
  margin-bottom: 6px;
  font-weight: 400;
  color: #878181;
}
.sc-text img {
  width: 100%;
}
.h-text h2 {
  font-size: 34px;
  color: #000;
  font-weight: 700;
  margin-bottom: 5px;
}
.award-text img {
  width: 100%;
}
.award-text.school-area h6 {
  font-size: 16px;
  padding: 0;
}
.award-text.school-area p {
  font-weight: 700;
}
.award-text.school-area p {
  font-size: 25px;
}
.gr {
  background: #5aa411;
  padding-bottom: 50px;
}
.yl {
  background: #f2ba13;
  padding-bottom: 50px;
}
.or {
  background: #09c8e1;
  padding-bottom: 50px;
}
.award-text.school-area h6 {
  color: #fff;
  margin-bottom: 5px;
}

.live1-text {
  margin: 0;
  border-top: 4px solid #f2ba13;
  box-shadow: 0 0 20px 0 #dddddd8c;
  transition: 0.5s all ease;
  background-color: #fff;
  padding: 10px;
}
.award-text.school-area p {
  font-size: 21px !important;
  padding-top: 20px;
  color: #fff !important;
  margin-bottom: 5px;
}

@media (max-width: 1042px) {
  .team-member h4 {
    padding: 0px 5px 0px !important;
  }
}
@media (max-width: 991px) {
  .empt-text h4 {
    font-size: 37px !important;
  }
  .trasd9-text p {
    padding: 5px 0px 0px 0px;
  }
  a.trnav-text22.nav-link {
    right: 22%;
  }
}
@media (max-width: 767px) {
  .live1-text {
    margin: 10px 0px;
    padding: 10px;
  }
  a.trnav-text22.nav-link {
    right: 38%;
  }
  .team-member h4 {
    padding: 13px 5px 0px !important;
  }
  section.tf-traxt {
    padding-top: 20px;
  }
  section.training-text {
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .section-team .single-person {
    margin-bottom: 10px;
  }

  section.section-team {
    padding-bottom: 0px;
  }
  .empt-text h4 {
    font-size: 27px !important;
  }
}
@media(max-width:575px){
  .trd-text {
    padding: 10px 0px 0px 4px;
}
section.training-text {
  padding-top: 10px;
  padding-bottom: 0px;
}
section.compte-text {
  padding-top: 0px;
  padding-bottom: 0px;
}
.new-item .react-photo-gallery--gallery img {
  width: 100%;
  height: 100%;
  margin-bottom: 10px !important;
  border-radius: 30px 30px 0 30px;
}
.light1box.mb-3 img {
  width: 100%;
  height: 100%;
}
section.lihood {
  margin-bottom: 20px;
}
}

@media (max-width: 425px) {
  section.compte-text {
    padding-bottom: 10px;
  }
  a.trnav-text22.nav-link {
    right: 35%;
  }
  .empt-text h4 {
    font-size: 22px !important;
  }
  .line {
    margin-bottom: 18px !important;
  }
  .tt_en {
    padding-top: 60px;
  }
  .d-health h6 {
    font-size: 19px;
    margin-bottom: 3px;
  }
  .trasd9-text h3 {
    font-size: 19px;
  }
  .live5-text h6 {
    padding: 0px 0px;
    font-size: 16px;
  }
  .train1-taxt h2 {
    font-size: 16px;
    line-height: 22px;
  }
}
@media (max-width: 328px) {
  .team-member h4 {
    font-size: 13px;
    padding: 0px 9px 0px;
  }
}
@media (max-width: 320px) {
  .empt-text h4 {
    font-size: 16px !important;
  }
  .tt_en {
    padding-top: 34px;
  }
  a.trnav-text22.nav-link {
    right: 27%;
  }
}
.new-item .react-photo-gallery--gallery img {
  border-radius: 30px 30px 0 30px;
}
