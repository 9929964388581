.gallary-tee h2 {
  text-align: center;
  font-size: 36px;
  margin-top: 33px;
  margin-bottom: 13px;
}
section.gallery-area.pt-80 {
  padding-bottom: 25px;
}
.gallary-tee h6 {
  text-align: center;
  margin: 0 auto;
  padding-bottom: 7px;
  text-align: center;
  max-width: 853px;
  font-size: 13px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eae9e9;
}
.galley-page {
  padding-top: 18px;
  padding-bottom: 4px;
}

@media (max-width: 991px) {
  .gallary-tee h2 {
    margin-bottom: 0px;
    text-align: center;
    font-size: 36px;
    margin-top: 33px;
  }
}
@media (max-width: 575px) {
  .gallary-tee h2 {
    margin-bottom: 0px;
    text-align: center;
    font-size: 26px;
    margin-top: 18px;
  }
}
@media (max-width: 320px) {
  .gallary-tee h2 {
    font-size: 20px;
  }
}
