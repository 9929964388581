.right {
  text-align: end;
}
ul.menu {
  display: flex;
  text-align: center;
  list-style-type: none;
  padding-top: 22px;
  padding-left: 20px;
}
.tik1 span.header-follow {
  color: #fff;
  font-size: 11px;
  position: relative;
  top: 2px;
}
.tik ul {
  padding-left: 10px;
}
a.rt {
  border-bottom: 1px solid #7c7b7b;
}
.header-area {
  background-color: #302c51;
  padding-top: 3px;
  padding-bottom: 0px;
  z-index: 999;
  position: relative;
}
.tik1 {
  position: relative;
  bottom: 0px;
}
.header-area .left ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 15px;
}
.header-area .left ul li i {
  display: inline-block;
  color: #fff;
  font-size: 16px;
}
.header-area .left ul li a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  padding-left: 6px;
  font-size: 13px;
}
.header-area .left ul li:last-child {
  margin-right: 0;
}
.header-area .right ul {
  display: inline-block;
  margin-right: 0px;
  margin-bottom: 0px;
}
.header-area .right ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 7px;
}
.header-area .right .header-search {
  display: inline-block;
  margin-right: 35px;
}
.header-area .right .header-search #search-btn {
  padding: 0;
  border-radius: 50%;
  color: #fff;
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  text-align: center;
  font-size: 13px;
  display: inline-block;
  line-height: 25px;
  cursor: pointer;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.header-area .right .header-search #search-overlay {
  display: none;
  z-index: 999999;
}
.header-area .right .header-search .block {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  background-color: #000000eb;
}
.header-area .right .header-search .centered {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  padding: 10px 15px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
}
.header-area .right .header-search .centered #search-box {
  position: relative;
}
.header-area .right .header-search #close-btn {
  padding: 0;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1;
  color: #fff;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  font-size: 25px;
  cursor: pointer;
}
.header-area .right .header-search .centered #search-box form .form-control {
  height: 50px;
  border: 0;
  border-radius: 5px;
  padding-left: 20px;
}
.header-area .right .header-search .centered #search-box form .form-control {
  height: 50px;
  border: 0;
  border-radius: 5px;
  padding-left: 20px;
}
i.icofont-facebook {
  color: #fff;
}
i.icofont-twitter {
  color: #fff;
}
i.icofont-youtube-play {
  color: #fff;
}
i.icofont-instagram {
  color: #fff;
}
.header-area .right ul li a {
  display: inline-block;
  width: 26px;
  /* padding-top: 1px; */
  height: 26px;
  text-align: center;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 12px;
}
i.icofont-heart-alt {
  padding-left: 8px;
  font-size: 14px;
}
.main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
/************************************************responsive********************/

@media (max-width: 991px) {
  .tik {
    display: block;
    margin-bottom: -12px;
    text-align: center;
}
.header-area .right ul {
  display: block;
  text-align: center;
  margin-bottom: 13px;
}
.header-area {
  background-color: #302c51;
  padding-top: 9px;
  padding-bottom: 9px;
  position: relative;
}
.header-area .right ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 5px;
}
}
@media (max-width: 400px) {
 
  .header-area .left ul li i {
    font-size: 15px;
  }
  .header-area .right ul li a {
    padding-top: 2px;
  }
}
@media (max-width: 320px){
.tik ul {
    padding-left: 0px;
}
.header-area .right ul li a {
  padding-top: 1px;
}
.header-area .right ul {
  margin-bottom: 13px;
  margin-left: -12px;
}
.header-area .left ul li {
  margin-right: 0px;
}
}